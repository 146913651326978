<template>
  <div class="viewbill">
    <a-spin :spinning="spinning">
      <!-- <react-table
        ref="listTable"
        :table-data="tableData"
        :total-items="totalItems"
        :showTotal="showTotalFun"
        :actions-type="actionsType"
        :columns="columns"
        :scroll="{ x: 2400 }"
        :is-full="true"
        :current-page="pageNumber"
        @emitRefreshData="clearFilterAndRefresh"
        @emitSortData="updateSortData"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      > -->
      <ele-table
        :columns="columns"
        :table-data="tableData"
        :total-items="totalItems"
        :is-full="true"
        :actionsType="actionsType"
        :current-page="pageNumber"
        :hideRowSelection="true"
        @emitRefreshData="clearFilterAndRefresh"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
        <!-- 按钮 -->
        <a-row :gutter="8">
          <!-- <a-col class="gutter-row" :span="19"> -->
          <a-col :span="4">
            <a-input
              v-model="searchFormInline.projectName"
              placeholder="请选择项目"
              @click="selectProjectModal"
            >
            </a-input>
            <a-input v-model="searchFormInline.projectId" type="hidden">
            </a-input>
          </a-col>
          <a-col :span="3">
            <a-input
              v-model="searchFormInline.clientName"
              placeholder="请选择客户"
              @click="selectCustomerModal"
            >
            </a-input>
            <a-input v-model="searchFormInline.clientId" type="hidden">
            </a-input>
          </a-col>
          <a-col :span="3">
            <a-input
              v-model="searchFormInline.signCompanyName"
              placeholder="签约公司"
              @click="selectOrgModal"
            >
            </a-input>
            <a-input v-model="searchFormInline.signCompanyId" type="hidden">
            </a-input>
          </a-col>
          <a-col :span="2">
            <a-month-picker placeholder="帐单月份" @change="monthChange" />
          </a-col>
          <a-col :span="2">
            <a-input-number
              style="width: 100%"
              placeholder="帐单金额"
              v-model="searchFormInline.billAmount"
              :min="0"
              :step="0.01"
            />
          </a-col>
          <a-col :span="3">
            <a-select
              style="width: 100%"
              show-search
              placeholder="帐单状态"
              v-model="searchFormInline.status"
              allowClear
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in statusOptions"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="2">
            <a-select
              style="width: 100%"
              show-search
              placeholder="帐单类型"
              v-model="searchFormInline.billType"
              allowClear
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in billTypeOptions"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="3">
            <a-button
              type="primary"
              @click="getData"
              v-if="isGranted('Pages.FinancialBillPermissions.Query')"
            >
              <!-- <a-icon type="search" /> -->
              {{ l("搜索") }}
            </a-button>
            <a-button @click="clearFilterAndRefresh">
              {{ l("重置") }}
            </a-button>
          </a-col>
          <a-col align="right" :span="2">
            <a-button-group>
              <a-button
                v-if="isGranted('Pages.FinancialBillPermissions.Export')"
                @click="exportToExcel()"
              >
                <a-icon type="file-excel" />
                <span>{{ l("Export") }}</span>
              </a-button>
            </a-button-group>
          </a-col>
        </a-row>
      </ele-table>
    </a-spin>

    <a-modal
      centered
      :title="addInvoiceModal.title"
      :visible="addInvoiceModal.visible"
      :confirm-loading="addInvoiceModal.confirmLoading"
      :maskClosable="addInvoiceModal.maskClosable"
      :destroyOnClose="addInvoiceModal.destroyOnClose"
      :width="addInvoiceModal.width"
      @ok="addInvoiceModal.confirm"
      @cancel="addInvoiceModal.cancel"
      :cancelText="addInvoiceModal.cancelText"
      :okText="addInvoiceModal.okText"
    >
      <div class="contentBox">
        <div class="billTitle">
          {{ addInvoiceModal.content }}
        </div>
        <div>
          <a-divider orientation="center"> 组织架构 </a-divider>
          <a-row type="flex">
            <a-col flex="120px" class="leftBox">名称：</a-col>
            <a-col flex="auto">{{
              addInvoiceModal.info.longName ? addInvoiceModal.info.longName : ""
            }}</a-col>
          </a-row>
          <a-row type="flex">
            <a-col flex="120px" class="leftBox">纳税人识别号：</a-col>
            <a-col flex="auto">{{
              addInvoiceModal.info.taxpayerNumber
                ? addInvoiceModal.info.taxpayerNumber
                : ""
            }}</a-col>
          </a-row>
          <a-divider orientation="center"> 客户 </a-divider>
          <a-row type="flex">
            <a-col flex="120px" class="leftBox">名称：</a-col>
            <a-col flex="auto">{{
              addInvoiceModal.info.title ? addInvoiceModal.info.title : ""
            }}</a-col>
          </a-row>
          <a-row type="flex">
            <a-col flex="120px" class="leftBox">纳税人识别号：</a-col>
            <a-col flex="auto">{{
              addInvoiceModal.info.number ? addInvoiceModal.info.number : ""
            }}</a-col>
          </a-row>
          <a-row type="flex">
            <a-col flex="120px" class="leftBox">地址、电话：</a-col>
            <a-col flex="auto">{{
              (addInvoiceModal.info.address
                ? addInvoiceModal.info.address + "、"
                : "") +
              (addInvoiceModal.info.telephone
                ? addInvoiceModal.info.telephone
                : "")
            }}</a-col>
          </a-row>
          <a-row type="flex">
            <a-col flex="120px" class="leftBox">开户行及账号：</a-col>
            <a-col flex="auto">{{
              (addInvoiceModal.info.bankName
                ? addInvoiceModal.info.bankName + "、"
                : "") +
              (addInvoiceModal.info.bankAccount
                ? addInvoiceModal.info.bankAccount
                : "")
            }}</a-col>
          </a-row>
          <a-divider orientation="center"> 客户或应税劳务、服务名称 </a-divider>
          <a-row type="flex">
            <a-col flex="120px" class="leftBox">发票信息：</a-col>
            <a-col flex="auto">{{
              addInvoiceModal.info.invoiceInfo
                ? addInvoiceModal.info.invoiceInfo
                : ""
            }}</a-col>
          </a-row>
        </div>
      </div>
    </a-modal>
  </div>
</template>


<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
import moment from "moment";
import ReactTable from "@/components/react-table";
import EleTable from "@/components/ele-table";
import ReactModify from "@/components/react-modify";
import SelectPageList from "@/components/linggong/select-page-list";
import { fileDownloadService } from "@/shared/utils";
export default {
  mixins: [AppComponentBase],
  components: {
    // ReactTable,
    EleTable,
  },
  data() {
    return {
      spinning: false,
      commonService: null,
      filterText: "",
      // 选择多少项
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      // 表格
      columns: [
        {
          title: this.l("客户名称"),
          dataIndex: "clientName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "clientName" },
          width: 220,
          // fixed: "left",
        },
        {
          title: this.l("状态"),
          dataIndex: "statusName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "statusName" },
          width: 100,
          // fixed: "left",
        },
        {
          title: this.l("项目名称"),
          dataIndex: "projectName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "projectName" },
          width: 250,
        },
        {
          title: this.l("备注"),
          dataIndex: "remark",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "remark" },
          width: 200,
        },
        {
          title: this.l("项目编码"),
          dataIndex: "projectCode",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "projectCode" },
          width: 250,
        },
        {
          title: this.l("开票抬头"),
          dataIndex: "invTitle",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "invTitle" },
          width: 200,
          // fixed: "left",
        },
        {
          title: this.l("账单月份"),
          dataIndex: "billMonth",
          sorter: false,
          width: 100,
          align: "center",
          scopedSlots: { customRender: "billMonth" },
        },
        {
          title: this.l("账单金额"),
          dataIndex: "billAmount",
          sorter: false,
          width: 120,
          align: "left",
          scopedSlots: { customRender: "billAmount" },
          customRender: (text) => {
            return `￥${text ? text : 0}`;
          },
        },
        {
          title: this.l("流水已认领金额"),
          dataIndex: "confimAmount",
          sorter: false,
          width: 120,
          align: "left",
          scopedSlots: { customRender: "confimAmount" },
          customRender: (text) => {
            return `￥${text ? text : 0}`;
          },
        },
        {
          title: this.l("账单已发放金额"),
          dataIndex: "sureAmount",
          align: "center",
          width: 120,
        },
        {
          title: this.l("应发"),
          dataIndex: "payAmount",
          sorter: false,
          width: 120,
          align: "center",
          scopedSlots: { customRender: "payAmount" },
          customRender: (text) => {
            return `￥${text ? text : 0}`;
          },
        },
        {
          title: this.l("实发"),
          dataIndex: "financePayingAmount",
          sorter: false,
          width: 120,
          align: "center",
          scopedSlots: { customRender: "financePayingAmount" },
          customRender: (text) => {
            return `￥${text ? text : 0}`;
          },
        },
        {
          title: this.l("个税"),
          dataIndex: "personalIncomeTax",
          sorter: false,
          width: 100,
          align: "center",
          scopedSlots: { customRender: "personalIncomeTax" },
          customRender: (text) => {
            return `￥${text ? text : 0}`;
          },
        },
        {
          title: this.l("服务费"),
          dataIndex: "serviceFee",
          sorter: false,
          width: 100,
          align: "center",
          scopedSlots: { customRender: "serviceFee" },
          customRender: (text) => {
            return `￥${text ? text : 0}`;
          },
        },
        {
          title: this.l("税金"),
          dataIndex: "tax",
          sorter: false,
          width: 100,
          align: "center",
          scopedSlots: { customRender: "tax" },
          customRender: (text) => {
            return `￥${text}`;
          },
        },
        {
          title: this.l("账单类型"),
          dataIndex: "billTypeStr",
          sorter: false,
          width: 120,
          align: "center",
          scopedSlots: { customRender: "billTypeStr" },
        },
        {
          title: this.l("账单说明"),
          dataIndex: "billMemo",
          sorter: false,
          width: 200,
          align: "center",
          scopedSlots: { customRender: "billMemo" },
        },
        {
          title: this.l("开票抬头"),
          dataIndex: "title",
          fixed: "right",
          sorter: false,
          width: 200,
          align: "center",
          scopedSlots: { customRender: "billMemo" },
        },
        {
          title: this.l("开票驳回原因"),
          dataIndex: "errorMessage",
          fixed: "right",
          sorter: false,
          width: 200,
          align: "center",
          scopedSlots: { customRender: "billMemo" },
        },
        // {
        //   title: this.l("Actions"),
        //   dataIndex: "actions",
        //   fixed: "right",
        //   sorter: false,
        //   align: "center",
        //   width: 120,
        //   scopedSlots: { customRender: "actions" },
        // },
      ],
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 }, //sorting {排序项 ASC/DESC}
      // list-table btns显示参数
      actionsType: {},
      searchFormInline: {
        clientId: undefined,
        clientName: "",
        status: undefined,
        signCompanyId: undefined,
        signCompanyName: "",
        projectId: undefined,
        projectName: "",
        billMonth: "",
        billType: undefined,
        billAmount: "",
      },
      orgOptions: [],
      cusOptions: [],
      projectOptions: [],
      statusOptions: [],
      billTypeOptions: [
        {
          label: "垫付帐单",
          value: 1,
        },
        {
          label: "非垫付帐单",
          value: 0,
        },
      ],
      invoiceTitleOptions: [],
      ncOrganzationTreeList: [],
      taxRate: 0,
      serviceChargeRate: 0,
      addInvoiceModal: {
        info: {},
        isShow: false,
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 500,
        title: "申请开票",
        url: "",
        cancelText: "取消",
        okText: "确认",
        form: {},
        confirm: () => {},
        cancel: () => {
          this.addInvoiceModal.isShow = false;
          this.addInvoiceModal.info = {};
          this.addInvoiceModal.confirm = () => {};
          this.addInvoiceModal.visible = false;
        },
      },
    };
  },
  created() {
    if (this.$route.query && this.$route.query.status) {
      this.searchFormInline.status = this.$route.query.status;
    }
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    //this.initActionsType();
    this.getData();
    this._getTopOrganizationUnitOption();
    this._getCustomerOption();
    this._getBillStatusList();
  },
  mounted() {},
  methods: {
    selectProjectModal() {
      ModalHelper.create(
        SelectPageList,
        {
          selectType: "checkbox", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/Project/GetSelectPaged", // 数据接口URL
          searchPlaceholder: "项目名称/项目代码",
          columnsData: [
            {
              title: "项目名称",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "项目代码",
              dataIndex: "description",
              key: "description",
            },
          ],
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        console.log(res);
        const { success, data } = res;
        if (success) {
          //   message.success("succed");
          //   console.log(data);
          let projectIds = "";
          let projectNames = "";
          for (let i = 0; i < data.length; i += 1) {
            const item = data[i];
            if (i === data.length - 1) {
              projectIds += item.id;
              projectNames += item.name;
              continue;
            }
            projectIds += item.id + ",";
            projectNames += item.name + ",";
          }

          this.searchFormInline.projectId = projectIds; //data.id;
          this.searchFormInline.projectName = projectNames; //data.name;

          this.getData();
          // this.getTaskByProjectId(0, data.id);
        }
      });
    },
    selectOrgModal() {
      ModalHelper.create(
        SelectPageList,
        {
          //   selectType: "checkbox", // checkbox radio
          title: "选择签约公司", // 显示标题
          url: "/api/services/app/OrganizationUnit/GetSelectPaged", // 数据接口URL
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          this.searchFormInline.signCompanyId = data.id;
          this.searchFormInline.signCompanyName = data.name;

          this.getData();
        }
      });
    },
    selectCustomerModal() {
      ModalHelper.create(
        SelectPageList,
        {
          //   selectType: "checkbox", // checkbox radio
          title: "选择客户", // 显示标题
          url: "/api/services/app/Customer/GetSelectPaged", // 数据接口URL
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          this.searchFormInline.clientId = data.id;
          this.searchFormInline.clientName = data.name;

          this.getData();
        }
      });
    },
    async getData() {
      this.spinning = true;
      try {
        console.log(this.searchFormInline);
        let res = await this.commonService.get({
          url: "/api/services/app/Bill/GetPagedByOrg",
          params: Object.assign(
            {
              ...this.searchFormInline,
              filterText: this.filterText,
              sorting: this.request.sorting,
              maxResultCount: this.request.maxResultCount,
              skipCount: this.request.skipCount,
            },
            {
              projectId: null,
              projectIds: this.searchFormInline.projectId,
            }
          ),
        });
        this.tableData = res.items.map((item) => {
          return {
            ...item,
            // standTypeStr: item.standType === 'Normal' ? '正常开票' : '独立开票'
            billTypeStr: item.billType === 0 ? "非垫付帐单" : "垫付帐单",
          };
        });
        this.totalItems = res.totalCount;
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
        this.totalPages = Math.ceil(
          res.totalCount / this.request.maxResultCount
        );
        // console.log(this.totalPages);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    _bulkAddInvoice() {
      if (!this.selectedRows.length) {
        this.$message.info("请选择至少一项");
        return;
      }
      let _this = this;
      const ids = this.selectedRows.map((item) => {
        return item.id;
      });
      let projectIds = this.selectedRows.map((item) => {
        return item.projectId;
      });
      projectIds = Array.from(new Set(projectIds));
      if (projectIds != null && projectIds.length > 1) {
        this.$message.info("请选择同一个项目");
        return;
      }
      this._getMoreInfo(ids[0]);
      // console.log(ids);
      let clientNameArr = this.selectedRows.map((item) => {
        return item.clientName;
      });
      clientNameArr = [...new Set(clientNameArr)];
      const str = clientNameArr.join("、");
      this.addInvoiceModal.title = "批量申请开票";
      this.addInvoiceModal.content = `是否对 ${str} 账单进行批量申请开票？`;
      this.addInvoiceModal.visible = true;
      this.addInvoiceModal.confirm = async function () {
        _this.spinning = true;
        try {
          let res = await _this.commonService.post({
            url: "/api/services/app/Bill/BulkAddInvoice",
            params: { ids: ids },
          });
          _this.spinning = false;
          _this.addInvoiceModal.visible = false;
          _this.getData();
          _this.notify.success(_this.l("账单确定成功"));
        } catch (error) {
          console.log(error);
          _this.addInvoiceModal.visible = false;
          _this.spinning = false;
        }
      };
    },
    _billConfirmRange() {
      if (!this.selectedRows.length) {
        this.$message.info("请选择至少一项");
        return;
      }
      let _this = this;

      const ids = this.selectedRows.map((item) => {
        return item.id;
      });
      let projectIds = this.selectedRows.map((item) => {
        return item.projectId;
      });
      projectIds = Array.from(new Set(projectIds));
      if (projectIds != null && projectIds.length > 1) {
        this.$message.info("请选择同一个项目");
        return;
      }
      this._getMoreInfo(ids[0]);
      console.log(ids);
      const clientNameArr = this.selectedRows.map((item) => {
        return item.clientName;
      });
      const str = clientNameArr.join("、");
      this.addInvoiceModal.title = "批量账单确认";
      this.addInvoiceModal.content = `是否对 ${str} 账单进行批量确认？`;
      this.addInvoiceModal.visible = true;
      this.addInvoiceModal.confirm = async function () {
        _this.spinning = true;
        _this.addInvoiceModal.confirmLoading = true;
        try {
          let res = await _this.commonService.post({
            url: "/api/services/app/Bill/ConfirmRange",
            params: { ids: ids },
          });
          _this.spinning = false;
          _this.addInvoiceModal.visible = false;
          _this.addInvoiceModal.confirmLoading = false;
          _this.getData();
          _this.notify.success(_this.l("账单确定成功"));
        } catch (error) {
          console.log(error);
          _this.addInvoiceModal.visible = false;
          _this.addInvoiceModal.confirmLoading = false;
          _this.spinning = false;
        }
      };
    },
    async _getMoreInfo(id) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Bill/GetMoreInfo",
          params: { id: id },
        });
        console.log(res);
        this.addInvoiceModal.info = res;
        this.addInvoiceModal.isShow = true;
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    _addInvoice(item) {
      let _this = this;
      this._getMoreInfo(item.id);
      this.addInvoiceModal.title = "申请开票";
      this.addInvoiceModal.content = `是否为 ${item.clientName} 申请开票？`;
      this.addInvoiceModal.visible = true;
      this.addInvoiceModal.confirm = async function () {
        _this.spinning = true;
        _this.addInvoiceModal.confirmLoading = true;
        try {
          let res = await _this.commonService.post({
            url: "/api/services/app/Bill/AddInvoice",
            params: { id: item.id },
          });
          _this.spinning = false;
          _this.addInvoiceModal.visible = false;
          _this.addInvoiceModal.confirmLoading = false;
          _this.getData();
          _this.notify.success(_this.l("申请成功"));
        } catch (error) {
          console.log(error);
          _this.spinning = false;
          _this.addInvoiceModal.confirmLoading = false;
          _this.addInvoiceModal.visible = false;
        }
      };
    },
    _billConfirm(item) {
      let _this = this;
      this._getMoreInfo(item.id);
      this.addInvoiceModal.title = "账单确认";
      this.addInvoiceModal.content = `是否对 ${item.clientName} 账单进行确认？`;
      this.addInvoiceModal.visible = true;
      this.addInvoiceModal.confirm = async function () {
        _this.spinning = true;
        _this.addInvoiceModal.confirmLoading = true;
        try {
          let res = await _this.commonService.post({
            url: "/api/services/app/Bill/Confirm",
            params: { id: item.id },
          });
          _this.spinning = false;
          _this.addInvoiceModal.visible = false;
          _this.addInvoiceModal.confirmLoading = false;
          _this.getData();
          _this.notify.success(_this.l("账单确定成功"));
        } catch (error) {
          console.log(error);
          _this.spinning = false;
          _this.addInvoiceModal.confirmLoading = false;
          _this.addInvoiceModal.visible = false;
        }
      };
    },
    _cancelBill(item) {
      let _this = this;
      this._getMoreInfo(item.id);
      this.addInvoiceModal.title = "账单作废";
      this.addInvoiceModal.content = `是否对 ${item.clientName} 账单作废？`;
      this.addInvoiceModal.visible = true;

      this.addInvoiceModal.confirm = async function () {
        _this.spinning = true;
        _this.addInvoiceModal.confirmLoading = true;
        try {
          let res = await _this.commonService.post({
            url: "/api/services/app/Bill/Cancel",
            params: { id: item.id },
          });
          _this.spinning = false;
          _this.addInvoiceModal.visible = false;
          _this.addInvoiceModal.confirmLoading = false;
          _this.getData();
          _this.notify.success(_this.l("账单作废成功"));
        } catch (error) {
          console.log(error);
          _this.spinning = false;
          _this.addInvoiceModal.confirmLoading = false;
          _this.addInvoiceModal.visible = false;
        }
      };
    },
    async _getTopOrganizationUnitOption() {
      this.spinning = true;
      try {
        const res = await this.commonService.get({
          url: "/api/services/app/OrganizationUnit/GetTopOrganizationUnitOption",
          params: null,
        });
        this.orgOptions = res;
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    async _getCustomerOption() {
      this.spinning = true;
      try {
        const res = await this.commonService.get({
          url: "/api/services/app/Customer/GetCustomerOption",
          params: null,
        });
        this.cusOptions = res;
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    //发票抬头
    async _getInvoiceTitleClients(customerId, cb) {
      this.spinning = true;
      try {
        const res = await this.commonService.get({
          url: "/api/services/app/Customer/GetInvoiceTitleClients",
          params: { customerId: customerId },
        });
        const arr = res.map((item) => {
          return {
            ...item,
            label: item.finSysCustomerNcCode
              ? item.title + "[HRO]"
              : item.title + "[未同步]",
            value: item.id,
            number: item.number,
          };
        });
        this.spinning = false;
        this.invoiceTitleOptions = arr;
        if (cb) cb(arr);
        return arr;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async _getNcOrganzationTreeList(organzationId, cb) {
      this.spinning = true;
      try {
        const res = await this.commonService.get({
          url: "/api/services/app/NCOrganization/GetNCOrganizationTreeSelect",
          params: { signCompanyId: organzationId },
        });

        this.spinning = false;
        if (cb) cb(res);
        return res;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    // 项目编码
    async _getAllProjectClients(clientId, cb) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Project/GetAllProjectClients",
          params: { clientId: clientId },
        });
        const arr = res.map((item) => {
          return {
            label: `${item.projectCode}-${item.projectName}`,
            value: item.id,
            projectName: item.projectName,
            projectCode: item.projectCode,
            projectId: item.id,
            signCompanyId: item.signCompanyId,
            taxRate: item.taxRate,
            serviceChargeRate: item.serviceChargeRate,
          };
        });
        this.spinning = false;
        this.projectOptions = arr;
        if (cb) cb(arr);
        return arr;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    monthChange(date, dateString) {
      console.log(date);
      console.log(dateString);
      this.searchFormInline.billMonth = dateString;
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      // this.clientId = "";
      // this.status = "";
      // this.signCompanyId = "";
      // this.billMonth = "";
      this.searchFormInline = {
        clientId: undefined,
        clientName: "",
        status: undefined,
        signCompanyId: undefined,
        signCompanyName: "",
        projectId: undefined,
        projectName: "",
        billMonth: "",
        billType: undefined,
        billAmount: "",
      };
      this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.filterText = "";
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      const res = this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
      return res;
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 初始化需要显示的func btn
    initActionsType() {
      let _this = this;
      let obj = {
        type: "seal",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted("Pages.BillPermissions.Update"),
              name: this.l("修改"),
              icon: "edit",
              fn: (data) => {
                if (data.status === 6) {
                  _this.notify.info(
                    // _this.l(`只有状态为"待客户确认"的账单才能进行修改`)
                    _this.l(`状态为"作废"的账单不能进行修改`)
                  );
                  return;
                }
                _this.createOrEdit(data, "edit");
              },
            },
            {
              granted: this.isGranted("Pages.BillPermissions.Sure"),
              name: this.l("确认账单"),
              icon: "profile",
              fn: (data) => {
                _this._billConfirm(data);
              },
            },
            {
              granted: this.isGranted("Pages.BillPermissions.FlowSure"),
              name: this.l("流水确认"),
              icon: "read",
              fn: (data) => {
                _this.createClaimantBill(data, 0);
              },
            },
            {
              // granted: this.isGranted("Pages.BillPermissions.FlowSure"),
              granted: true,
              name: this.l("流水退回"),
              icon: "read",
              fn: (data) => {
                _this.createClaimantBill(data, 1);
              },
            },
            {
              granted: this.isGranted("Pages.BillPermissions.Apply"),
              name: this.l("申请开票"),
              icon: "form",
              fn: (data) => {
                _this._addInvoice(data);
              },
            },
            {
              granted: this.isGranted("Pages.BillPermissions.Cancel"),
              name: this.l("账单作废"),
              icon: "form",
              fn: (data) => {
                _this._cancelBill(data);
              },
            },
          ],
          // delete: { granted: this.isGranted('Pages.Customer.Delete'), name: this.l("Delete"), fn: (data) => {
          //   _this.deleteItem(data.id)
          // } },
        },
      };
      this.actionsType = obj;
    },
    async _getBillStatusList() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Bill/GetBillStatusList",
          params: {},
        });
        this.statusOptions = res.map((item) => {
          return {
            ...item,
            label: item.text,
          };
        });
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async exportToExcel() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Bill/GetToBillExcelFileByOrg",
          params: Object.assign(
            {
              ...this.searchFormInline,
              filterText: this.filterText,
              sorting: this.request.sorting,
            },
            {
              projectId: null,
              projectIds: this.searchFormInline.projectId,
            }
          ),
          // {
          //   ...this.searchFormInline,
          //   filterText: this.filterText,
          //   sorting: this.request.sorting,
          // },
        });
        console.log(res);
        fileDownloadService.downloadTempFile(res);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async _confirmRange() {
      let ids = [];

      this.spinning = true;
      try {
        const res = await this.commonService.post({
          url: "/api/services/app/Bill/ConfirmRange",
          params: { ids: ids },
        });
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async createOrEdit(item, type) {
      let myForm;

      let projectList = [];
      let signCompanyId = -1;
      if (item && item.clientId) {
        projectList = await this._getAllProjectClients(
          item.clientId,
          (arr) => {}
        );
        let hoverProject = projectList.filter((value) => {
          return value.value.toLowerCase() == item.projectId.toLowerCase();
        });
        if (hoverProject.length > 0) {
          signCompanyId = hoverProject[0].signCompanyId;
        }
      }

      const list = [
        {
          label: "客户名称",
          value: "clientId",
          type: "commonSelect",
          disabled: false,
          required: true,
          rules: [{ required: true, message: "请选择" }],
          defaultValue: "",
          defaultSelectList: this.cusOptions,
          span: 12,
          changeFn: (v) => {
            this.taxRate = 0;
            this.serviceChargeRate = 0;
            this._getInvoiceTitleClients(v, (arr) => {
              list.forEach((_it) => {
                if (_it.value === "companyInvoiceId") {
                  // console.log(_it);
                  _it.defaultValue = "";
                  // myForm.resetFields(['companyInvoiceId'])
                  _it.defaultSelectList = arr;
                }
              });
            });
            this._getAllProjectClients(v, (arr) => {
              list.forEach((_it) => {
                if (_it.value === "projectId") {
                  _it.defaultValue = "";
                  _it.defaultSelectList = arr;
                }
              });
            }),
              myForm.resetFields([
                "projectId",
                "companyInvoiceId",
                "invNumber",
              ]);
            // console.log(list);
          },
        },
        {
          label: "项目编码",
          value: "projectId",
          type: "commonSelect",
          disabled: false,
          required: true,
          rules: [{ required: true, message: "请选择" }],
          defaultValue: "",
          defaultSelectList: projectList,
          span: 12,
          changeFn: (v) => {
            let hover = {};
            list.forEach((_it) => {
              if (_it.value === "projectId") {
                console.log(_it.value, v);
                _it.defaultSelectList.forEach((_ct) => {
                  if (_ct.value == v) {
                    hover = _ct;
                  }
                });
              }
            });
            this.taxRate = hover.taxRate;
            this.serviceChargeRate = hover.serviceChargeRate;
            this._getNcOrganzationTreeList(hover.signCompanyId, (arr) => {
              list.forEach((_it) => {
                if (_it.value === "ncDeptId") {
                  _it.defaultValue = "";
                  _it.defaultSelectList = arr;
                }
              });
            });

            myForm.resetFields(["tax", "billAmount", "serviceFee"]);
          },
        },
        {
          label: "账单月份",
          value: "billMonth",
          type: "monthPicker",
          disabled: false,
          required: true,
          rules: [{ required: true, message: "请选择" }],
          defaultValue: "",
          defaultSelectList: [],
          span: 12,
          changeFn: (date, dateString) => {
            console.log(date, dateString);
          },
        },
        {
          label: "账单金额",
          value: "billAmount",
          type: "numberInput",
          min: 0,
          max: 9,
          disabled: false,
          required: true,
          rules: [{ required: true, message: this.l("请输入") }],
          defaultValue: "",
          span: 12,
          changeFn: (e) => {
            // console.log(e);
            if (typeof e !== "number") return;
            list.forEach((_it) => {
              if (_it.value === "tax") {
                // console.log(this.taxRate, parseFloat(e.target.value));
                // console.log(this.taxRate, parseFloat(e))
                _it.defaultValue = Number(
                  // this.taxRate * parseFloat(e.target.value)
                  parseFloat(e) - parseFloat(e) / (this.taxRate + 1)
                )
                  .toFixed(2)
                  .toString();
                myForm.setFieldsValue({
                  tax: _it.defaultValue,
                });
              }

              if (_it.value === "serviceFee") {
                // console.log(this.taxRate, parseFloat(e.target.value));
                console.log(this);
                _it.defaultValue = Number(
                  // this.taxRate * parseFloat(e.target.value)
                  (parseFloat(e) * this.serviceChargeRate) / 100
                )
                  .toFixed(2)
                  .toString();
                myForm.setFieldsValue({
                  serviceFee: _it.defaultValue,
                });
              }
            });
          },
        },
        {
          label: "应发",
          value: "payAmount",
          type: "numberInput",
          min: 0,
          max: 9,
          disabled: false,
          required: true,
          // rules: [{ max: 128, message: this.l("MaxLength") }],
          rules: [{ required: true, message: this.l("请输入") }],
          defaultValue: "",
          span: 12,
          changeFn: () => {},
        },
        {
          label: "银行手续费",
          value: "personalIncomeTax",
          type: "numberInput",
          min: 0,
          max: 9,
          disabled: false,
          required: true,
          // rules: [{ max: 128, message: this.l("MaxLength") }],
          rules: [{ required: true, message: this.l("请输入") }],
          defaultValue: "",
          changeFn: () => {},
        },
        {
          label: "实发",
          value: "financePayingAmount",
          type: "numberInput",
          min: 0,
          max: 9,
          disabled: false,
          required: true,
          // rules: [{ max: 128, message: this.l("MaxLength") }],
          rules: [{ required: true, message: this.l("请输入") }],
          defaultValue: "",
          span: 12,
          changeFn: () => {},
        },
        {
          label: "服务费",
          value: "serviceFee",
          type: "numberInput",
          min: 0,
          max: 9,
          disabled: false,
          required: true,
          // rules: [{ max: 128, message: this.l("MaxLength") }],
          rules: [{ required: true, message: this.l("请输入") }],
          defaultValue: "",
          span: 12,
          changeFn: () => {},
        },
        {
          label: "税金",
          value: "tax",
          type: "numberInput",
          min: 0,
          max: 9,
          disabled: false,
          required: true,
          // rules: [{ max: 128, message: this.l("MaxLength") }],
          rules: [{ required: true, message: this.l("请输入") }],
          defaultValue: "",
          span: 12,
          changeFn: () => {},
        },
        {
          label: "商业保险",
          value: "commercialInsurance",
          type: "numberInput",
          min: 0,
          max: 9,
          disabled: false,
          required: true,
          // rules: [{ max: 128, message: this.l("MaxLength") }],
          rules: [{ required: true, message: this.l("请输入") }],
          defaultValue: "",
          span: 12,
          changeFn: () => {},
        },
        {
          label: "发票抬头",
          value: "companyInvoiceId",
          type: "commonSelect",
          disabled: false,
          required: true,
          rules: [{ required: true, message: "请选择" }],
          defaultValue: "",
          defaultSelectList:
            item && item.clientId
              ? await this._getInvoiceTitleClients(item.clientId, (arr) => {})
              : [],
          // defaultSelectList: [],
          span: 12,
          changeFn: (v) => {
            myForm.setFieldsValue({
              invNumber: this.invoiceTitleOptions.filter(
                (_v) => _v.value === v
              )[0].number,
            });
          },
        },

        {
          label: "纳税人识别号",
          value: "invNumber",
          type: "commonInput",
          disabled: true,
          required: true,
          rules: [{ max: 128, message: this.l("MaxLength") }],
          defaultValue: "",
          span: 12,
          changeFn: () => {},
        },
        {
          label: "账单说明",
          value: "billMemo",
          type: "commonInput",
          disabled: false,
          required: false,
          defaultValue: "",
          rules: [{ max: 128, message: this.l("MaxLength") }],
          span: 12,
          changeFn: () => {},
        },
        {
          label: "开票标准",
          value: "standType",
          type: "commonSelect",
          disabled: false,
          required: true,
          rules: [{ required: true, message: "请选择" }],
          defaultValue: "",
          defaultSelectList: [
            { label: "正常开票", value: 0 },
            { label: "独立开票", value: 1 },
          ],
          span: 12,
          changeFn: () => {},
        },
        {
          label: "账单类型",
          value: "billType",
          type: "commonSelect",
          disabled: false,
          required: true,
          rules: [{ required: true, message: "请选择" }],
          defaultValue: "",
          defaultSelectList: [
            { label: "非垫付帐单", value: 0 },
            { label: "垫付帐单", value: 1 },
          ],
          span: 12,
          changeFn: () => {},
        },
        {
          label: "NC部门",
          value: "ncDeptId",
          type: "commonTreeSelect",
          disabled: false,
          required: true,
          rules: [{ required: true, message: "请选择" }],
          defaultValue: "",
          defaultSelectList:
            signCompanyId == -1
              ? []
              : await this._getNcOrganzationTreeList(signCompanyId),
          span: 12,
          changeFn: () => {},
        },
        {
          label: "备注",
          value: "remark",
          type: "commonInput",
          disabled: false,
          required: false,
          defaultValue: "",
          rules: [{ max: 128, message: this.l("MaxLength") }],
          span: 12,
          changeFn: () => {},
        },
      ];
      let invoiceContent = {
        label: "发票内容",
        value: "invoiceContent",
        type: "commonInput",
        disabled: false,
        required: false,
        rules: [{ max: 128, message: this.l("MaxLength") }],
        defaultValue: "",
        span: 12,
        changeFn: () => {},
      };
      let reqParams = [];
      // 后续接口不需要id
      if (item && item.id) reqParams.push({ key: "id", value: item.id });
      let otherPrams = {};
      ModalHelper.create(
        ReactModify,
        {
          params: {
            type: type, // edit,preview,create
            data: list,
            reqParams: [...reqParams],
            reqUrl: {
              getById: `/api/services/app/Bill/GetBillById`,
              save: `/api/services/app/Bill/SaveBill`,
            },
            otherParams: {},
            okBtnTxt: "确定",
            cancelBtnTxt: "关闭",
            initFn: async (form, options) => {
              myForm = form;
              // console.log('initFn')
              // if (!item) {
              //   form.setFieldsValue({projectCode: await this._getProjectCode()})
              // } else {
              //   console.log(item)
              //   form.setFieldsValue({payAmount: item.payAmount, serviceFee: item.serviceFee, financePayingAmount: item.financePayingAmount})
              // }
            },
            getItemfn: async (res, form, options) => {
              form.setFieldsValue({
                ...res,
                companyInvoiceId: res.companyInvoiceId
                  ? res.companyInvoiceId.toLowerCase()
                  : "",
                billType: res.billType === "Advance" ? 1 : 0,
              });
              if (options) {
                // ...
              }
            },
            submitfn: async (values, queryParams) => {
              let obj = {};
              queryParams.reqParams.map((item) => {
                obj[item.key] = item.value;
              });
              Object.assign(values, obj);
              console.log(values, this.invoiceTitleOptions);
              let clientName = this.cusOptions.filter(
                (_item) => _item.value === values.clientId
              )[0].label;
              let invTitle = this.invoiceTitleOptions.filter(
                (_item) => _item.value === values.companyInvoiceId
              )[0].label;
              let project = this.projectOptions.filter(
                (_item) => _item.value === values.projectId
              )[0];
              let projectName = project.projectName;
              let projectCode = project.projectCode;
              try {
                let res = await this.commonService.post({
                  url: queryParams.reqUrl.save,
                  params: {
                    ...values,
                    clientName: clientName,
                    invTitle: invTitle,
                    projectName: projectName,
                    projectCode: projectCode,
                  },
                });
                return true;
              } catch (error) {
                return false;
              }
            },
          },
        },
        {
          isChange: true,
          width: "900px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
    createClaimantBill(item, type) {
      ModalHelper.create(
        type == 0 ? ClaimantBill : RejectClaimantBill,
        {
          params: {
            type: type, // 1 流水确认  2流水退回
            organizationUnitId: item ? item.organizationUnitId : null,
            id: item ? item.id : null,
          },
        },
        {
          isChange: true,
          width: "1000px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.bill {
  min-height: 600px;
}
.leftBox {
  // color: red;
  // font-weight: bold;
  font-size: 15px;
}
.billTitle {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  color: red;
}
</style>